import { render, staticRenderFns } from "./EditActivityType.vue?vue&type=template&id=87f3f990&scoped=true"
import script from "./EditActivityType.vue?vue&type=script&lang=js"
export * from "./EditActivityType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87f3f990",
  null
  
)

export default component.exports