<template>
  <div>
    <Dropdown
      ref="dropdown"
      label="designation"
      :options="activityTypesFiltered"
      @input="onInput"
      required
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

import Dropdown from '@/components/activity/edit/Dropdown.vue';

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      value: null
    }
  },
  components: {
    Dropdown
  },
  computed: {
    ...mapGetters({
      activityTypes: "activityTypes"
    }),
    activityTypesFiltered() {
      // we filter by activity type family
      // two cases :
      // 1. it's in currentEditedEntry, for example when we create a new tour
      // 2. otherwise, we take the family of the selected value
      const groupCode = this.currentEditedEntry.groupCode || this.value?.groupCode

      // if no family or we are duplicating a tour, return complete list
      if(!groupCode || this.isDuplicateMode) return this.activityTypes

      return this.activityTypes.filter(t => t.activityTypeGroups.includes(groupCode));
    },
    isDuplicateMode() {
      return this.$route.name === "tour.duplicate"
    }
  },
  async mounted() {
    await this.$store.dispatch("getAllActivityTypes");

    // case: the entry has already an activity type
    if(this.currentEditedEntry.activityType || this.currentEntry.activityType)
      this.value = this.activityTypes.find(t => t.id == (this.currentEditedEntry.activityType || this.currentEntry.activityType));
    // case : there is only one activity type, then select it
    else if(this.activityTypesFiltered.length == 1)
      this.value = this.activityTypesFiltered[0]

    this.$refs["dropdown"].setValue(this.value)
  },
  methods: {
    onInput(activityType) {
      if(!activityType) return

      this.$store.dispatch(this.storeEditAction, {
        activityType: activityType.id,
        activityType_code: activityType.code,
        activityType_needInputs: activityType.needInputs,
        activityType_needGrowthStage: activityType.needGrowthstage,
        activityType_needTargets: activityType.needTargets

      });

      this.$store.dispatch("tour/getAllInputForm");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
